export const cadenceOptionsValues = {
  month: [
    { value: 2, label: "Every 2 Weeks" },
    { value: 4, label: "Every 4 Weeks", labelNewRx: "Every 4 Weeks <b>(Recommended)</b>" },
    { value: 6, label: "Every 6 Weeks" },
  ],
  month3: [
    { value: 2, label: "Every 2 Months" },
    { value: 3, label: "Every 3 Months" },
    { value: 4, label: "Every 4 Months" },
  ],
  month6: [
    { value: 5, label: "Every 5 Months" },
    { value: 6, label: "Every 6 Months" },
    { value: 7, label: "Every 7 Months" },
  ],
  year: [
    { value: 10, label: "Every 10 Months" },
    { value: 12, label: "Every 12 Months" },
  ]
}

export const extendedMonthCadencePortalOptionsValues = [
    { value: '14', label: "Every 2 Weeks" },
    { value: '21', label: "Every 3 Weeks" },
    { value: '28', label: "Every 4 Weeks" },
    { value: '35', label: "Every 5 Weeks" },
    { value: '42', label: "Every 6 Weeks" },
]

export const cadencePortalOptionsValues = {
  month: [
    { value: '14', label: "Every 2 Weeks" },
    { value: '28', label: "Every 4 Weeks" },
    { value: '42', label: "Every 6 Weeks" },
  ],
  month3: [
    { value: '56', label: "Every 2 Months" },
    { value: '84', label: "Every 3 Months" },
    { value: '112', label: "Every 4 Months" },
  ],
  month6: [
    { value: '140', label: "Every 5 Months" },
    { value: '168', label: "Every 6 Months" },
    { value: '196', label: "Every 7 Months" },
  ],
  year: [
    { value: '280', label: "Every 10 Months" },
    { value: '336', label: "Every 12 Months" },
  ]
}

export const quantityOptionsValues = {
  classic: [
    {
      value: 30,
      portalValue: 1,
      label: "<span>30 pairs (1 month supply) <b>$19.99/eye </b></span>",
      labelText: "30 pairs (1 month supply)",
      labelTextOneEye: "30 lenses (1 month supply)",
      labelOneEye: "<span>30 lenses (1 month supply) <b>$19.99 </b></span>",
      Off15LabelCustomerPortal: "<p>30 pairs (1 month supply)<br><b><strike>$19.99</strike> $16.99/eye <span class='purple'>(save 15%)</span></b></p>",
      labelCustomerPortal: "<p>30 pairs (1 month supply)<br><b>$19.99/eye </b></p>",
      labelCustomerPortalOneEye: "<p>30 pairs (1 month supply)<br><b>$19.99 </b></p>",
      price: "19.99",
      oldPrice: "",
      duplicatePrice: "9.99",
      militaryPrice: "16.99",
      lifemartPrice: "16.99",
      discount: "",
    },
    {
      value: 90,
      portalValue: 3,
      label: "<span>90 pairs (3 month supply) <b><strike>$59.97</strike> $49.99/eye (save 17%)</b></span>", 
      labelText: "90 pairs (3 month supply)",
      labelTextOneEye: "90 lenses (3 month supply)",
      labelOneEye: "<span>90 lenses (3 month supply) <b><strike>$59.97</strike> $49.99 (save 17%)</b></span>",
      labelCustomerPortal: "<p>90 pairs (3 month supply)<br><b><strike>$59.97</strike> $49.99/eye <span class='purple'>(save 17%)</span></b></p>",
      labelCustomerPortalOneEye: "<p>90 pairs (3 month supply)<br><b><strike>$59.97</strike> $49.99 <span class='purple'>(save 17%)</span></b></p>",
      price: "49.99",
      oldPrice: "59.97",
      discount: "17%",
      lifemartPrice: "50.97",
    },
    {
      value: 180,
      portalValue: 6,
      label: "<span>180 pairs (6 month supply) <b><strike>$119.94</strike> $94.99/eye (save 21%)</b></span>",
      labelText: "180 pairs (6 month supply)",
      labelTextOneEye: "180 lenses (6 month supply)",
      labelOneEye: "<span>180 lenses (6 month supply) <b><strike>$119.94</strike> $94.99 (save 21%)</b></span>",
      labelCustomerPortal: "<p>180 pairs (6 month supply)<br><b><strike>$119.94</strike> $94.99/eye <span class='purple'>(save 21%)</span></b></p>",
      labelCustomerPortalOneEye: "<p>180 pairs (6 month supply)<br><b><strike>$119.94</strike> $94.99 <span class='purple'>(save 21%)</span></b></p>",
      price: "94.99",
      oldPrice: "119.94",
      discount: "21%",
      lifemartPrice: "101.94",
    },
    {
      value: 360,
      portalValue: 12,
      label: "<span>360 pairs (yearly supply) <b><strike>$239.88</strike> $179.99/eye (save 25%)</b></span>",
      labelText: "360 pairs (yearly supply)",
      labelTextOneEye: "360 lenses (yearly supply)",
      labelOneEye: "<span>360 lenses (yearly supply) <b><strike>$239.88</strike> $179.99 (save 25%)</b></span>",
      labelCustomerPortal: "<p>360 pairs (yearly supply)<br><b><strike>$239.88</strike> $179.99/eye <span class='purple'>(save 25%)</span></b></p>",
      labelCustomerPortalOneEye: "<p>360 pairs (yearly supply)<br><b><strike>$239.88</strike> $179.99 <span class='purple'>(save 25%)</span></b></p>",
      price: "179.99",
      oldPrice: "239.88",
      discount: "25%",
      lifemartPrice: "203.89",
    },
  ],
  hydro: [
    {
      value: 30,
      portalValue: 1,
      label: "<span>30 pairs (1 month supply) <b>$24.99/eye</b></sp>",
      labelText: "30 pairs (1 month supply)",
      labelTextOneEye: "30 lenses (1 month supply)",
      labelOneEye: "<span>30 lenses (1 month supply) <b>$24.99</b></span>",
      Off15LabelCustomerPortal: "<p>30 pairs (1 month supply)<br><b><strike>$24.99</strike> $21.24/eye <span class='purple'>(save 15%)</span></b></p>",
      labelCustomerPortal: "<p>30 pairs (1 month supply)<br><b>$24.99/eye</b></p>",
      labelCustomerPortalOneEye: "<p>30 pairs (1 month supply)<br><b>$24.99</b></p>",
      price: "24.99",
      oldPrice: "",
      militaryPrice: "21.24",
      lifemartPrice: "21.24",
      discount: "",
    },
    {
      value: 90,
      portalValue: 3,
      label: "<span>90 pairs (3 month supply) <b><strike>$74.97</strike> $54.99/eye (save 27%)</b></span>",
      labelText: "90 pairs (3 month supply)",
      labelTextOneEye: "90 lenses (3 month supply)",
      labelOneEye: "<span>90 lenses (3 month supply) <b><strike>$74.97</strike> $54.99 (save 27%)</b></span>",
      labelCustomerPortal: "<p>90 pairs (3 month supply)<br><b><strike>$74.97</strike> $54.99/eye <span class='purple'>(save 27%)</span></b></p>",
      labelCustomerPortalOneEye: "<p>90 pairs (3 month supply)<br><b><strike>$74.97</strike> $54.99 <span class='purple'>(save 27%)</span></b></p>",
      price: "54.99",
      oldPrice: "74.97",
      discount: "27%",
      lifemartPrice: "63.72",
    },
    {
      value: 180,
      portalValue: 6,
      label: "<span>180 pairs (6 month supply) <b><strike>$149.94</strike> $99.99/eye (save 33%)</b></span>",
      labelText: "180 pairs (6 month supply)",
      labelTextOneEye: "180 lenses (6 month supply)",
      labelOneEye: "<span>180 lenses (6 month supply) <b><strike>$149.94</strike> $99.99 (save 33%)</b></span>",
      labelCustomerPortal: "<p>180 pairs (6 month supply)<br><b><strike>$149.94</strike> $99.99/eye <span class='purple'>(save 33%)</span></b></p>",
      labelCustomerPortalOneEye: "<p>180 pairs (6 month supply)<br><b><strike>$149.94</strike> $99.99 <span class='purple'>(save 33%)</span></b></p>",
      price: "99.99",
      oldPrice: "149.94",
      discount: "33%",
      lifemartPrice: "127.44",
    },
    {
      value: 360,
      portalValue: 12,
      label: "<span>360 pairs (yearly supply) <b><strike>$299.88</strike> $189.99/eye (save 36%)</b></span>",
      labelText: "360 pairs (yearly supply)",
      labelTextOneEye: "360 lenses (yearly supply)",
      labelOneEye: "<span>360 lenses (yearly supply) <b><strike>$299.88</strike> $189.99 (save 36%)</b></span>",
      labelCustomerPortal: "<p>360 pairs (yearly supply)<br><b><strike>$299.88</strike> $189.99/eye <span class='purple'>(save 36%)</span></b></p>",
      labelCustomerPortalOneEye: "<p>360 pairs (yearly supply)<br><b><strike>$299.88</strike> $189.99 <span class='purple'>(save 36%)</span></b></p>",
      price: "189.99",
      oldPrice: "299.88",
      discount: "36%",
      lifemartPrice: "254.89",
    },
  ],
  skyhy: [
    {
      value: 30,
      portalValue: 1,
      label: "<span>30 pairs (1 month supply) <b>$29.99/eye</b></sp>",
      labelText: "30 pairs (1 month supply)",
      labelTextOneEye: "30 lenses (1 month supply)",
      labelOneEye: "<span>30 lenses (1 month supply) <b>$29.99</b></sp>",
      Off15LabelCustomerPortal: "<p>30 pairs (1 month supply)<br><b><strike>$29.99</strike> $25.49/eye <span class='purple'>(save 15%)</span></b></p>",
      labelCustomerPortal: "<p>30 pairs (1 month supply)<br><b>$29.99/eye</b></p>",
      labelCustomerPortalOneEye: "<p>30 pairs (1 month supply)<br><b>$29.99</b></p>",
      price: "29.99",
      oldPrice: "",
      militaryPrice: "25.49",
      lifemartPrice: "25.49",
      discount: "",
    },
    {
      value: 90,
      portalValue: 3,
      label: "<span>90 pairs (3 month supply) <b><strike>$89.97</strike> $64.99/eye (save 28%)</b></span>",
      labelText: "90 pairs (3 month supply)",
      labelTextOneEye: "90 lenses (3 month supply)",
      labelOneEye: "<span>90 lenses (3 month supply) <b><strike>$89.97</strike> $64.99 (save 28%)</b></span>",
      labelCustomerPortal: "<p>90 pairs (3 month supply)<br><b><strike>$89.97</strike> $64.99/eye <span class='purple'>(save 28%)</span></b></p>",
      labelCustomerPortalOneEye: "<p>90 pairs (3 month supply)<br><b><strike>$89.97</strike> $64.99 <span class='purple'>(save 28%)</span></b></p>",
      price: "64.99",
      oldPrice: "89.97",
      discount: "28%",
      lifemartPrice: "76.47",
    },
    {
      value: 180,
      portalValue: 6,
      label: "<span>180 pairs (6 month supply) <b><strike>$179.94</strike> $116.99/eye (save 35%)</b></span>",
      labelText: "180 pairs (6 month supply)",
      labelTextOneEye: "180 lenses (6 month supply)",
      labelOneEye: "<span>180 lenses (6 month supply) <b><strike>$179.94</strike> $116.99 (save 35%)</b></span>",
      labelCustomerPortal: "<p>180 pairs (6 month supply)<br><b><strike>$179.94</strike> $116.99/eye <span class='purple'>(save 35%)</span></b></p>",
      labelCustomerPortalOneEye: "<p>180 pairs (6 month supply)<br><b><strike>$179.94</strike> $116.99 <span class='purple'>(save 35%)</span></b></p>",
      price: "116.99",
      oldPrice: "179.94",
      discount: "35%",
      lifemartPrice: "152.94",
    },
    {
      value: 360,
      portalValue: 12,
      label: "<span>360 pairs (yearly supply) <b><strike>$359.88</strike> $222.99/eye (save 38%)</b></span>",
      labelText: "360 pairs (yearly supply)",
      labelTextOneEye: "360 lenses (yearly supply)",
      labelOneEye: "<span>360 lenses (yearly supply) <b><strike>$359.88</strike> $222.99 (save 38%)</b></span>",
      labelCustomerPortal: "<p>360 pairs (yearly supply)<br><b><strike>$359.88</strike> $222.99/eye <span class='purple'>(save 38%)</span></b></p>",
      labelCustomerPortalOneEye: "<p>360 pairs (yearly supply)<br><b><strike>$359.88</strike> $222.99 <span class='purple'>(save 38%)</span></b></p>",
      price: "222.99",
      oldPrice: "359.88",
      discount: "38%",
      lifemartPrice: "305.89",
    },
  ],
  // acuvue oasys 1 day with hydraluxe
  '1765069357156': {
    price: "84.00", 
    oldPrice: "104.99",
    cadence: "3 month",
    lensQuantity: "90",
    discount: "20"
  },
  // DAILIES AquaComfort Plus
  '1765114970212': {
    price: "56.25", 
    oldPrice: "74.99",
    cadence: "3 month",
    lensQuantity: "90",
    discount: "25"
  },
  // DAILIES TOTAL1 90pk
  '2620910534756': {
    price: "92.00", 
    oldPrice: "114.99",
    cadence: "3 month",
    lensQuantity: "90",
    discount: "20"
  },
  // Biotrue ONEday for Presbyopia
  '3408938401892': {
    price: "80.00", 
    oldPrice: "99.99",
    cadence: "3 month",
    lensQuantity: "90",
    discount: "20"
  },
  // Biotrue ONEday 90pk
  '1765111332964': {
    price: "52.50", 
    oldPrice: "69.99",
    cadence: "3 month",
    lensQuantity: "90",
    discount: "25"
  },
  // Acuvue Oasys for Astigmatism 6pk
  '1765080399972': {
    price: "45.00", 
    oldPrice: "59.99",
    cadence: "3 month",
    lensQuantity: "6",
    discount: "25"
  },
  // Acuvue Oasys for Astigmatism 30pk
  '1765072797796': {
    price: "37.50", 
    oldPrice: "49.99",
    cadence: "1 month",
    lensQuantity: "30",
    discount: "25"
  },
  // Acuvue Oasys With Hydraclear Plus 6pk
  '1765078499428': {
    price: "34.50", 
    oldPrice: "45.99",
    cadence: "3 month",
    lensQuantity: "6",
    discount: "25"
  },
  // 1-Day Acuvue Moist
  '2620861448292': {
    price: "60.00", 
    oldPrice: "79.99",
    cadence: "3 month",
    lensQuantity: "90",
    discount: "25"
  },
}

export const julyPriceTestClassic = {
  value: 30,
  portalValue: 1,
  label: "<span>30 pairs (1 month supply) <b>$22.99/eye </b></span>",
  labelText: "30 pairs (1 month supply)",
  labelTextOneEye: "30 lenses (1 month supply)",
  labelOneEye: "<span>30 lenses (1 month supply) <b>$22.99 </b></span>",
  Off15LabelCustomerPortal: "<p>30 pairs (1 month supply)<br><b><strike>$22.99</strike> $16.99/eye <span class='purple'>(save 15%)</span></b></p>",
  labelCustomerPortal: "<p>30 pairs (1 month supply)<br><b>$22.99/eye </b></p>",
  labelCustomerPortalOneEye: "<p>30 pairs (1 month supply)<br><b>$22.99 </b></p>",
  price: "22.99",
  oldPrice: "",
  duplicatePrice: "9.99",
  militaryPrice: "16.99",
  lifemartPrice: "16.99",
  discount: "",
}

export const prescriptionLabels = {
  rightEye: "RX - Right Eye",
  leftEye: "RX - Left Eye",
}
